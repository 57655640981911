import { faArrowRight, faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEnhancedQuery } from "@uplift-ltd/apollo";
import { notEmpty } from "@uplift-ltd/ts-helpers";
import React from "react";
import { PracticeToolsHomepageQuery } from "components/PrivatePracticeTools/__generated__/queries";
import { PRACTICE_TOOLS_HOMEPAGE_QUERY } from "components/PrivatePracticeTools/queries";
import { ButtonLink } from "components/common/Button";
import { PRIVATE_PRACTICE_TOOLS_ROOT_URL } from "constants/urls";
import styles from "./PrivatePracticeTools.module.css";

const PrivatePracticeTools = () => {
  const { data, loading } = useEnhancedQuery<PracticeToolsHomepageQuery>(
    PRACTICE_TOOLS_HOMEPAGE_QUERY
  );

  if (loading || !data) {
    return null;
  }

  const tools = data.products?.edges
    ?.filter(notEmpty)
    .filter(e => e.node?.privatePracticeTools?.shortName);

  if (!tools?.length) {
    return null;
  }

  return (
    <section className={styles.sectionContainer}>
      <div className={styles.sectionTitle}>
        <FontAwesomeIcon className="mr-2" icon={faInbox} />
        Private Practice Forms
      </div>
      <div className={styles.formGroup}>
        {tools.map(({ node }) => (
          <ButtonLink
            key={node?.id}
            href={`${PRIVATE_PRACTICE_TOOLS_ROOT_URL}/${node?.slug || ""}`}
            className={styles.button}
          >
            {node?.privatePracticeTools?.shortName}
            <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
          </ButtonLink>
        ))}
      </div>
    </section>
  );
};

export default PrivatePracticeTools;
