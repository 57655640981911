import React from "react";
import { CeRequirementsInner } from "components/CeRequirements";
import { useCeRequirementFieldsOptions } from "hooks/useCeRequirementFieldsOptions";
import styles from "./CeRequirementsBox.module.css";

const CeRequirementsBox = () => {
  const { professionOptions } = useCeRequirementFieldsOptions();

  return (
    <section className={styles.sectionContainer}>
      <div className={styles.sectionTitle}>Check your CE requirements</div>
      <div className={styles.form}>
        <CeRequirementsInner professionOptions={professionOptions} />
      </div>
    </section>
  );
};

export default CeRequirementsBox;
