import Image from "next/image";
import React from "react";
import CeRequirementsBox from "components/Home/CeRequirementsBox";
import HtmlContent from "components/common/HtmlContent";
import HeroImg from "images/welcome-zur-institute.svg";
import EventsHome from "../Events";
import styles from "./Hero.module.css";

interface HeroProps {
  title: string;
  subtitle: string;
}

const Hero: React.FC<HeroProps> = ({ title, subtitle }) => {
  return (
    <section className={styles.heroContainer}>
      <div className={styles.heroText}>
        <div className={styles.heroTitle}>{title}</div>
        <div className={styles.heroContent}>
          <HtmlContent className={styles.heroSubTitle}>{subtitle}</HtmlContent>
          <div className={styles.heroImage}>
            <Image src={HeroImg} alt="Welcome to Zur Institute" />
          </div>
        </div>
        <CeRequirementsBox />
      </div>
      <div className={styles.heroWebinars}>
        <EventsHome />
      </div>
    </section>
  );
};

export default Hero;
