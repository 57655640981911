import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeUrl } from "@uplift-ltd/strings";
import cx from "classnames";
import React from "react";
import { HeroSection, StaticPage } from "components/StaticPageTemplate/queries";
import { SubscriptionProductsQuerySubscriptionProducts } from "components/Subscriptions/__generated__/queries";
import { useSubscriptionProducts } from "components/Subscriptions/hooks";
import {
  getBillingWording,
  getSubscriptionName,
  SubscriptionInterval,
} from "components/Subscriptions/subscriptionUtils";
import { ButtonLink } from "components/common/Button";
import Container from "components/common/Container";
import HtmlContent from "components/common/HtmlContent";
import Link from "components/common/Link";
import { FullPageLoading } from "components/common/Loading";
import SectionHeader from "components/common/SectionHeader";
import { SUBSCRIPTIONS_URL } from "constants/urls";
import { formatUSD } from "helpers/strings";
import styles from "./SubscriptionProducts.module.css";

interface ArticleProps {
  subscriptionProduct: SubscriptionProductsQuerySubscriptionProducts;
  isHomepage?: boolean;
}

const Product = ({ subscriptionProduct, isHomepage }: ArticleProps) => {
  const price = subscriptionProduct.prices?.[0];

  if (!price) {
    return null;
  }

  const href = makeUrl(SUBSCRIPTIONS_URL, { slug: subscriptionProduct.slug });

  const billingWording = getBillingWording(
    price.interval as SubscriptionInterval,
    price.intervalCount
  );

  const name = getSubscriptionName(subscriptionProduct.name);

  return (
    <article className={cx(styles.product, isHomepage && styles.homepageProduct)}>
      <div className={cx(styles.description, isHomepage && styles.homepageDescription)}>
        <h2 className={styles.productTitle}>
          <Link href={href}>
            <FontAwesomeIcon className="mr-2" icon={faStar} />
            {name}
          </Link>
        </h2>
        <HtmlContent className={styles.info}>{subscriptionProduct.shortDescription}</HtmlContent>
      </div>
      <div className={styles.price}>
        <div className={styles.priceUSD}>{formatUSD(Number(price.price))}</div>
        {billingWording && <div className={styles.billingWording}>{billingWording}</div>}
        <div className={styles.learnMore}>
          <ButtonLink theme="light" href={href}>
            Learn More
          </ButtonLink>
        </div>
      </div>
    </article>
  );
};

type Props = Omit<HeroSection, "__typename"> & {
  content: StaticPage["content"];
};

export const SubscriptionProductsList = ({ isHomepage }: { isHomepage?: boolean }) => {
  const products = useSubscriptionProducts();

  if (products.loading) {
    return <FullPageLoading />;
  }

  return (
    <>
      {products.data?.subscriptionProducts?.map(product => (
        <Product key={product.id} subscriptionProduct={product} isHomepage={isHomepage} />
      ))}
    </>
  );
};

const SubscriptionProducts = ({ title, summary, content }: Props) => {
  return (
    <>
      {title && summary && <SectionHeader title={title} summary={summary} />}
      {content && (
        <div>
          <HtmlContent>{content}</HtmlContent>
        </div>
      )}
      <Container>
        <SubscriptionProductsList />
      </Container>
    </>
  );
};

export default SubscriptionProducts;
