import { GRAPHQL_UNAUTH_URL } from "@uplift-ltd/constants";
import React from "react";
import { PageIdType } from "__generated__/globalTypes";
import Home from "components/Home";
import { PRACTICE_TOOLS_HOMEPAGE_QUERY } from "components/PrivatePracticeTools/queries";
import {
  STATIC_PAGE_QUERY,
  StaticPageQuery,
  StaticPageQueryVariables,
} from "components/StaticPageTemplate";
import Layout from "components/common/Layout";
import { HOME_HERO_MENU_ID } from "constants/site";
import { SeoPageProps } from "helpers/seo";
import { withEnhancedServerSideProps } from "helpers/withEnhancedProps";
import {
  MENU_ITEMS_QUERY,
  MenuItemsQuery,
  MenuItemsQueryVariables,
} from "hooks/useMenuItemsContext";

const Index = ({ seo, title }: SeoPageProps) => {
  return (
    <Layout initialMeta={seo}>
      <Home title={title} />
    </Layout>
  );
};

export default Index;

export const getServerSideProps = withEnhancedServerSideProps<SeoPageProps>(async ctx => {
  if (ctx.isInitialRequest) {
    const [page] = await Promise.all([
      ctx.apolloClient.query<StaticPageQuery, StaticPageQueryVariables>({
        query: STATIC_PAGE_QUERY,
        variables: { id: "/", idType: PageIdType.URI },
      }),
      ctx.apolloClient.query<MenuItemsQuery, MenuItemsQueryVariables>({
        query: MENU_ITEMS_QUERY,
        variables: {
          menuId: HOME_HERO_MENU_ID,
        },
        context: {
          uri: GRAPHQL_UNAUTH_URL,
        },
      }),
      ctx.apolloClient.query({
        query: PRACTICE_TOOLS_HOMEPAGE_QUERY,
      }),
    ]);

    return {
      props: {
        seo: page.data.page?.seo,
        title: page.data.page?.seo?.title || page.data.page?.title || "",
      },
    };
  }

  return {
    props: { title: "" },
  };
});
